<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <router-link :to="{ name: 'admin.movers.create' }" class="btn btn-sm btn-primary float-end">Add New</router-link>
        <h3>Movers</h3>
      </div>
    </div>
    <div v-if="!loading" class="card">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-md-5">
            <form @submit.prevent="fetch">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Movers"
                  v-model="search_term"
                />
                <button class="btn btn-primary"><i class="lni lni-search"></i></button>
              </div>
            </form>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="5" v-if="movers.length == 0">
                There is no data to display.
              </td>
            </tr>
            <tr v-for="(row, i) in movers" :key="`request-${i}`">
              <td>{{ row.name }}</td>
              <td>{{ row.email }}</td>
              <td>{{ row.phone_number }}</td>
              <td>{{ row.status }}</td>
              <td>
                <router-link
                  :to="{ name: 'admin.movers.show', params: { id: row.id } }"
                >
                  Details
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination
              v-model="pagination.page"
              :records="pagination.records"
              @paginate="fetch"
              :perPage="10"
            />
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      movers: [],
      search_term: "",
      pagination: { page: 1, records: 1 },
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/admin/movers?search=${this.search_term}&page=${this.pagination.page}`)
        .then((response) => {
          this.movers = response.data.movers;
          this.pagination = response.data.pagination;
          this.loading = false;
        });
    },
  },
};
</script>
